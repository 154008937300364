@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


@layer base {
  html {
    font-family: "Raleway", -apple-system, BlinkMacSystemFont, " Segoe UI", " Roboto", " Oxygen", " Ubuntu", " Cantarell", " Fira Sans", " Droid Sans",
      "Helvetica Neue", sans-serif;
  }
}

@layer utilities {
  .hero-bg {
    background-image: url("../public/img/hero/hero-bg.svg");
  }
}

.nav {
  transition: background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 86px 0;
}

.swiper-pagination-bullet-active {
  background-color: #3F3F95 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}